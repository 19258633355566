<script setup lang="ts"></script>

<template>
  <div class="flex justify-center">
    <p class="text-base mx-3">
      {{ $t('jpc-assign-bonus-information') }}
    </p>
  </div>
</template>

<style scoped lang="scss"></style>
